import React from "react";
import service1WebP from "../images/service-1-500w.webp";
import service2WebP from "../images/service-2-500w.webp";
import service3WebP from "../images/service-3-500w.webp";
import service1 from "../images/service-1-500w.jpg";
import service2 from "../images/service-2-500w.jpg";
import service3 from "../images/service-3-500w.jpg";
import TextButton from "../elements/TextButton";
import { useNavigate } from "react-router-dom";
import { ServiceInfo } from "./types";
import { motion } from "framer-motion";

const serviceInfos: Array<ServiceInfo> = [
  {
    name: "service1",
    label: "Project Management",
    alt: "Our project management expert advising a client with their current project",
    desc: "Work with our experts specialized in project management. We will provide guidance and support throughout the project lifecycle, from planning and budgeting to execution and monitoring. ",
    price: 10000,
    image: service1,
    imageWebP: service1WebP,
  },
  {
    name: "service2",
    label: "Operational Consulting",
    alt: "An Ixius consultant expert meeting with a client as they discuss business operations",
    desc: "Our operational consulting services are designed to help businesses optimize their operations and improve their overall efficiency.",
    price: 7500,
    image: service2,
    imageWebP: service2WebP,
  },
  {
    name: "service3",
    label: "Corporate Strategy",
    alt: "Our expert working with a client on a white board to develop a working strategy",
    desc: "Our strategy service is a result-oriented solution that combines in-depth analysis, strategic planning, and implementation support to help your organization achieve sustainable growth and success",
    price: 6500,
    image: service3,
    imageWebP: service3WebP,
  },
];

function Services() {
  let navigate = useNavigate();

  const handleClick = (info: ServiceInfo) => {
    navigate("/booking", {
      state: {
        info: {
          ...info,
          consultant: "Jane Reyes",
          date: new Date(),
          location: "Quezon City",
        },
      },
    });
  };

  return (
    <div className="mx-4 my-8">
      <h1 className="my-8 font-serif text-center text-accent">SERVICES</h1>
      <div>
        {serviceInfos.map((info, index) => (
          <motion.section
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0, transition: { delay: 0.2 * index } }}
            className="my-8 border-2 border-solid border-slate-300 sm:grid sm:grid-cols-2 sm:mx-auto sm:w-11/12 sm:items-center sm:gap-5 md:w-4/5 md:gap-8 2xl:w-3/5 [&_img]:first:object-[35%_50%]"
            key={info.name}
          >
            <div className="w-full h-full">
              <picture>
                <source srcSet={info.imageWebP} type="image/webp" />
                <source srcSet={info.image} type="image/jpeg" />
                <img
                  id={info.name}
                  width={500}
                  height={352}
                  src={info.image}
                  alt={info.alt}
                  className="object-cover w-full h-full bg-gray-100"
                  loading="lazy"
                />
              </picture>
            </div>
            <div className="p-4 [&>*]:my-4">
              <h2 className="font-bold text-main">
                {info.label.toUpperCase()}
              </h2>
              <p>{info.desc}</p>
              <h2 className="text-slate-500">{`₱${info.price.toLocaleString(
                "En"
              )}/hour`}</h2>
              <TextButton text="Book Now" onClick={() => handleClick(info)} />
            </div>
          </motion.section>
        ))}
      </div>
    </div>
  );
}

export default Services;
