import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const navButtonInfos = [
  {
    name: "About",
    page: "about",
  },
  {
    name: "Services",
    page: "services",
  },
  {
    name: "Projects",
    page: "projects",
  },
  {
    name: "Plans & Pricing",
    page: "plans-and-pricing",
  },
  {
    name: "Contact",
    page: "contact",
  },
];

function Header() {
  const [navBarVisible, setNavBarVisible] = useState(false);
  let navigate = useNavigate();

  const handleHeaderClick = () => {
    setNavBarVisible(!navBarVisible);
  };

  const handleNavBarClick = (page: string) => {
    navigate(page);
    setNavBarVisible(false);
  };

  const renderNavButtons = () => {
    return navButtonInfos.map((navButtonInfo) => {
      return (
        <button
          key={navButtonInfo.name}
          className={
            navBarVisible ? "p-4 border-b-[1px] sm:my-0" : "p-1 my-2 sm:my-0"
          }
          onClick={() => {
            handleNavBarClick(navButtonInfo.page);
          }}
        >
          {navButtonInfo.name}
        </button>
      );
    });
  };

  return (
    <header className="fixed top-0 z-10 w-full bg-main">
      <div className="p-1 text-center shadow-sm bg-main text-font-main">
        <div className="sm:mx-8 md:mx-12 2xl:mx-24">
          <div className="flex items-center justify-between">
            <h1 className="p-1 mx-2">
              <Link to="/">IXIUS</Link>
            </h1>
            <div className="block p-1 mx-2 sm:hidden">
              <button
                onClick={handleHeaderClick}
                aria-label="Open navigation menu"
              >
                <FontAwesomeIcon icon={faBars} className="fa-xl" />
              </button>
            </div>
            <nav className="hidden p-1 mx-2 space-x-4 sm:flex">
              {renderNavButtons()}
            </nav>
          </div>
        </div>
      </div>
      <nav
        className={
          navBarVisible
            ? "grid grid-cols-1 bg-main m:hidden text-center text-font-main border-y-[1px]"
            : "hidden"
        }
      >
        {renderNavButtons()}
      </nav>
    </header>
  );
}

export default Header;
