import React from "react";
import project1WebP from "../images/project-1-500w.webp";
import project2WebP from "../images/project-2-500w.webp";
import project3WebP from "../images/project-3-500w.webp";
import project4WebP from "../images/project-4-500w.webp";
import project1 from "../images/project-1-500w.jpg";
import project2 from "../images/project-2-500w.jpg";
import project3 from "../images/project-3-500w.jpg";
import project4 from "../images/project-4-500w.jpg";
import { motion } from "framer-motion";

const projectInfos = [
  {
    name: "project1",
    label: "DIVANA KRO Investments",
    desc: "Our specialists facilitated Divana's fund management and found market opportunities with project KRO. KRO investment increased company revenues by over twenty percent.",
    image: project1,
    imageWebP: project1WebP,
    alt: "Divana meeting room with Ixius and our partners calculating data in a conference table",
  },
  {
    name: "project2",
    label: "NINT Cash Flow",
    desc: "Ixius has an ongoing project with NINT to improve company cash flow. Our data-driven approach to find inefficiencies in business operations have yielded continuous positive results.",
    image: project2,
    imageWebP: project2WebP,
    alt: "NINT staff discussing their company business specifics in a casual office",
  },
  {
    name: "project3",
    label: "Eximity Tax Optimization",
    desc: "Ixius advanced Eximity's business operations with our detailed audit. Our team analyzed project cost strategies and found improvements for administrative policies. We are working with Eximity with a structured plan to optimize gaps in tax management.",
    image: project3,
    imageWebP: project3WebP,
    alt: "Eximity representative relaying good news about our tax policy results",
  },
  {
    name: "project4",
    label: "Bauch Partnership",
    desc: "Our partnership with Bauch enabled its rapid expansion in the consumer industry. Our experts studied Bauch's market position and created unique financing models that empowered Bauch's team to scale risk-free.",
    image: project4,
    imageWebP: project4WebP,
    alt: "Bauch's fulfillment center featuring a large and organized warehouse",
  },
];

function Projects() {
  return (
    <div className="mx-4 my-8">
      <h1 className="my-4 font-serif text-center sm:my-8 text-accent">
        PROJECTS
      </h1>
      <div className="sm:grid sm:grid-cols-2 sm:mx-auto sm:gap-8 sm:w-4/5 2xl:w-3/5 ">
        {projectInfos.map((info, index) => (
          <motion.section
            key={info.name}
            initial={{ opacity: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="mb-12"
          >
            <picture>
              <source srcSet={info.imageWebP} type="image/webp" />
              <source srcSet={info.image} type="image/jpeg" />
              <img
                width={500}
                height={375}
                src={info.image}
                alt={info.alt}
                className="object-cover w-full bg-gray-100"
                loading="lazy"
              />
            </picture>
            <h2 className="my-4 font-bold text-main">
              {info.label.toUpperCase()}
            </h2>
            <p>{info.desc}</p>
          </motion.section>
        ))}
      </div>
    </div>
  );
}

export default Projects;
