import { EMAIL_REGEX } from "../constants/RegexValidation";

export type InputRuleType = "email" | "mobile" | "required";

type InputRule = {
  name: InputRuleType;
  message: string;
};

export interface FormInfo {
  name: string;
  label: string;
  type: string;
  value: string;
  errors: Array<InputRule>;
  inputRules: Array<InputRule>;
}

function isValidEmail(value: string) {
  let validEmail = false;

  if (value) {
    const email = value.toLowerCase();
    const emailRE = EMAIL_REGEX;
    validEmail = !!email.match(emailRE);
  }

  return validEmail;
}

function isValidMobile(value: string) {
  let validMobile = false;

  if (value) {
    const mobile = value;
    const mobileRE = /^(\+63|0)\d{10}$/;
    validMobile = !!mobile.match(mobileRE);
  }
  return validMobile;
}

function isValidRequired(value: string) {
  return !!value;
}

function validateInput(info: FormInfo, excludeRules?: Array<InputRuleType>) {
  let inputErrors: Array<InputRule> = info.inputRules.reduce((errors, rule) => {
    const excluded = excludeRules?.includes(rule.name);
    if (excluded) return errors;

    const input = info.value.trim();

    if (!input) {
      if (rule.name === "required" && !isValidRequired(input)) {
        errors.push(rule);
      }
    } else {
      switch (rule.name) {
        case "email": {
          if (!isValidEmail(input)) {
            errors.push(rule);
          }
          break;
        }
        case "mobile": {
          if (!isValidMobile(input)) {
            errors.push(rule);
          }
          break;
        }
      }
    }

    return errors;
  }, [] as Array<InputRule>);

  return { ...info, errors: inputErrors };
}

export function validateForm<FormType>(
  infos: Array<FormType>,
  excludeRules?: Array<InputRuleType>
): Array<FormType> {
  const validatedForm = infos.map((info) => {
    return { ...info, ...validateInput(info as FormInfo, excludeRules) };
  });
  return validatedForm;
}
