import React from "react";
import TextButton from "../elements/TextButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import { PlanInfo } from "./types";
import { motion } from "framer-motion";

const planInfos: Array<PlanInfo> = [
  {
    name: "plan1",
    label: "Financial Strategy",
    desc: "We will develop a strategy based on thorough analysis of your business processes, as well as your long-term objectives.",
    price: 50000,
    duration: "1 month",
    recommended: false,
    benefits: [
      {
        name: "benefit_1",
        label: "Asset Allocation",
      },
      {
        name: "benefit_2",
        label: "Long-term Strategy",
      },
      {
        name: "benefit_3",
        label: "Funding Support",
      },
    ],
  },
  {
    name: "plan2",
    label: "Management Plans",
    desc: "Our management plans are practical and actionable. We will study your business needs and provide a clear statement of objectives to improve operations.",
    price: 100000,
    duration: "1 month",
    recommended: true,
    benefits: [
      {
        name: "benefit_1",
        label: "Business Roadmap",
      },
      {
        name: "benefit_2",
        label: "Factor Management",
      },
      {
        name: "benefit_3",
        label: "Innovative PM Tools",
      },
    ],
  },
  {
    name: "plan3",
    label: "Performance Assessment",
    desc: "Let our experts glean important information on your performance. We will help you find key areas for improvement.",
    price: 180000,
    duration: "1 month",
    recommended: false,
    benefits: [
      {
        name: "benefit_1",
        label: "Objective Assessment",
      },
      {
        name: "benefit_2",
        label: "Growth Opportunities",
      },
      {
        name: "benefit_3",
        label: "Efficiency Analysis",
      },
    ],
  },
];

function PlansPricing() {
  let navigate = useNavigate();

  const handleClick = (info: PlanInfo) => {
    navigate("/checkout", {
      state: {
        product: {
          type: "plan",
          info: info,
        },
      },
    });
  };

  return (
    <div className="mx-4 my-8">
      <div className="my-4 sm:w-4/5 sm:mx-auto">
        <h1 className="my-4 font-serif text-center text-accent">
          GROWTH AND INNOVATION PLANS
        </h1>
        <p className="mb-8 text-center">
          Ixius will work with you to monitor your organization's finances and
          find key areas for improvement
        </p>
      </div>

      <div className="block sm:w-3/5 sm:mx-auto lg:grid lg:grid-cols-3 lg:w-4/5 lg:gap-8 2xl:w-3/5">
        {planInfos.map((info, index) => (
          <motion.section
            key={info.name}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0, transition: { delay: 0.2 * index } }}
            className="relative p-4 my-8 text-center border-2 border-solid rounded-lg border-slate-300"
          >
            {info.recommended && (
              <div className="absolute top-0 left-1/2 p-2 translate-x-[-50%] translate-y-[-50%] rounded-md bg-slate-500 text-font-main">
                Recommended
              </div>
            )}

            <div className="[&>*]:my-4 h-full">
              <h2 className="mx-auto font-bold text-main w-min">
                {info.label.toUpperCase()}
              </h2>
              <h2 className="text-slate-500">{`₱${info.price.toLocaleString(
                "En"
              )}/month`}</h2>
              <p className="flex items-center lg:h-40">{info.desc}</p>
              <TextButton
                text="Get Plan"
                onClick={() => {
                  handleClick(info);
                }}
              />
              <div className="mx-auto w-fit">
                {info.benefits.map((benefit) => (
                  <div
                    key={benefit.name}
                    className="flex items-center justify-start my-2 space-x-2"
                  >
                    <span className="text-accent">
                      <FontAwesomeIcon icon={faCircleCheck} className="fa-lg" />
                    </span>
                    <span>{benefit.label}</span>
                  </div>
                ))}
              </div>
            </div>
          </motion.section>
        ))}
      </div>
    </div>
  );
}

export default PlansPricing;
