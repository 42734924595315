import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="px-4 py-8 bg-main text-font-main">
      <div className="flex flex-wrap justify-between max-w-7xl sm:w-4/5 sm:mx-auto">
        <section className="mr-12">
          <h2 className="my-2">Ixius Consulting</h2>
          <div className="my-4">
            <div>1452 Balintawak Street 1000</div>
            <div>Quezon City</div>
            <div>Metro Manila</div>
            <div>Mail: info@ixiusconsulting.com</div>
            <div>Tel: (+63)02-716-3728</div>
          </div>
        </section>
        <div className="flex justify-between">
          <div className="hidden mr-24 lg:block">
            <h2 className="my-2">Menu</h2>
            <nav className="leading-8">
              <div>
                <Link to="/about">About</Link>
              </div>
              <div>
                <Link to="/projects">Projects</Link>
              </div>
              <div>
                <Link to="/services">Services</Link>
              </div>
              <div>
                <Link to="/plans-and-pricing">Plans & Pricing</Link>
              </div>
              <div>
                <Link to="/contact">Contact</Link>
              </div>
            </nav>
          </div>
          <section className="grow-0">
            <h2 className="my-2">Socials</h2>
            <div className="my-4">
              <Link
                to="https://www.facebook.com"
                aria-label="Go to Facebook"
                className="p-4"
              >
                <FontAwesomeIcon icon={faFacebookF} className="p-1 fa-xl" />
              </Link>
              <Link
                to="https://www.twitter.com"
                aria-label="Go to Twitter"
                className="p-4"
              >
                <FontAwesomeIcon icon={faTwitter} className="p-1 fa-xl" />
              </Link>
            </div>
            <div>© 2023 Ixius Consulting</div>
          </section>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
