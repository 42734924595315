import React, { Fragment, useState } from "react";
import IconButton from "../elements/IconButton";
import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";

interface CalendarDay {
  date: Date;
  monthDay: number;
  isActive: boolean;
  isDayOfCalendarMonth: boolean;
}

interface CalendarProps {
  selectedDate: Date;
  todayDate: Date;
  handleSelectDate: (date: Date) => void;
}

const MAXIMUM_MONTH_STEP = 2;
const ALLOW_WEEKENDS = true;

function Calendar(props: CalendarProps) {
  const { selectedDate, todayDate, handleSelectDate } = props;
  const minMonth = todayDate.getMonth();
  const maxMonth = todayDate.getMonth() + MAXIMUM_MONTH_STEP;
  const [viewDate, setViewDate] = useState(todayDate);

  const handleCalendarButtonClick = (day: CalendarDay) => {
    handleSelectDate(new Date(day.date));
  };

  const handleMonthClick = (step: number) => {
    const newDate = new Date(viewDate);
    const newMonth = newDate.getMonth() + step;

    if (newMonth <= maxMonth && newMonth >= minMonth) {
      newDate.setMonth(newDate.getMonth() + step);
      setViewDate(newDate);
    }
  };

  const isWorkDay = (date: Date) => {
    const isWorkDay = !(date.getDay() === 0) && !(date.getDay() === 6);

    return ALLOW_WEEKENDS || isWorkDay;
  };

  const isSameDate = (dateA: Date, dateB: Date) => {
    const isSameDate =
      dateA.getDate() === dateB.getDate() &&
      dateA.getMonth() === dateB.getMonth() &&
      dateA.getFullYear() === dateB.getFullYear();

    return isSameDate;
  };

  const getCalendarDays = (month: number, year: number) => {
    const cols = 7;
    const rows = 6;
    const days = new Array(cols * rows);

    const startDate = new Date(year, month, 1);
    const today = new Date();
    const adjustment = startDate.getDay();

    for (var i = 0; i < days.length; i++) {
      let date = new Date(startDate);
      date.setDate(date.getDate() - adjustment + i);

      let day = {
        date: date,
        monthDay: date.getDate(),
        isActive: isWorkDay(date) && date > today,
        isDayOfCalendarMonth: date.getMonth() === startDate.getMonth(),
      };

      days[i] = day;
    }

    return days;
  };

  const renderCalendarHeaders = () => {
    const headers = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

    return (
      <Fragment>
        {headers.map((header) => {
          return (
            <div key={header} className="text-center">
              {header}
            </div>
          );
        })}
      </Fragment>
    );
  };

  const renderCalendarButtons = (day: CalendarDay) => {
    const defaultStyle = "w-full h-full text-center";
    const paddingStyle = "w-full h-full text-center text-gray-400";
    const activeStyle = "w-full h-full text-center border-[1px]";
    const selectedStyle = "w-full h-full text-center border-[1px] bg-blue-100 ";
    const todayStyle = "w-full h-full text-center border-2 border-blue-300";

    let buttonStyle = defaultStyle;
    if (day.isActive) buttonStyle = activeStyle;
    if (!day.isDayOfCalendarMonth) buttonStyle = paddingStyle;
    if (isSameDate(day.date, todayDate)) buttonStyle = todayStyle;
    if (
      selectedDate.getMonth() === viewDate.getMonth() &&
      isSameDate(day.date, selectedDate)
    )
      buttonStyle = selectedStyle;

    return (
      <button
        className={buttonStyle}
        disabled={!day.isActive || !day.isDayOfCalendarMonth}
        onClick={() => {
          handleCalendarButtonClick(day);
        }}
      >
        {day.monthDay}
      </button>
    );
  };

  return (
    <div>
      <div className="grid grid-cols-[1fr,50%,1fr] items-center justify-center my-8">
        <div className="flex justify-center">
          <IconButton
            ariaLabel="Go to previous month"
            icon={faCaretLeft}
            onClick={() => {
              handleMonthClick(-1);
            }}
            iconSize="fa-2xl"
            color="border-transparent text-slate-600"
            active={viewDate.getMonth() > minMonth}
          />
        </div>
        <div className="text-center">
          <h2 className="text-accent">
            {viewDate.toLocaleString("en-US", {
              year: "numeric",
            })}
          </h2>
          <h2 className="text-accent">
            {viewDate.toLocaleString("en-US", {
              month: "long",
            })}
          </h2>
        </div>
        <div className="flex justify-center">
          <IconButton
            ariaLabel="Go to next month"
            icon={faCaretRight}
            onClick={() => {
              handleMonthClick(1);
            }}
            iconSize="fa-2xl"
            color="border-transparent text-slate-600"
            active={viewDate.getMonth() < maxMonth}
          />
        </div>
      </div>

      <div className="grid grid-cols-[repeat(7,minmax(0,1fr))] justify-center gap-[1px] items-center">
        {renderCalendarHeaders()}
        {getCalendarDays(viewDate.getMonth(), viewDate.getFullYear()).map(
          (day, index) => {
            return (
              <div className="aspect-square" key={index}>
                {renderCalendarButtons(day)}
              </div>
            );
          }
        )}
      </div>
    </div>
  );
}

export default Calendar;
