import React from "react";
import TextButton from "../elements/TextButton";
import bannerWebP from "../images/banner-1920w.webp";
import banner from "../images/banner-1920w.jpg";
import { useNavigate } from "react-router-dom";

function Banner() {
  const navigate = useNavigate();

  return (
    <section className="">
      <picture>
        <source srcSet={bannerWebP} type="image/webp" />
        <source srcSet={banner} type="image/jpeg" />
        <img
          width={1920}
          height={853}
          src={banner}
          alt=""
          className="object-cover max-h-[50vmin] w-screen bg-gray-100"
        />
      </picture>

      <div className="static md:h-14 2xl:h-20 md:relative">
        <div className="static px-2 py-8 text-center bg-slate-50 drop-shadow-md md:absolute md:translate-y-[-75%] md:translate-x-[-50%] md:left-1/2 md:drop-shadow-md md:w-fit md:rounded-md md:p-10 2xl:p-20">
          <p className="text-2xl">Outlasting Strategies</p>
          <h1 className="my-6 font-serif text-4xl text-accent">
            UNYIELDING EXPANSION
          </h1>
          <div className="">
            <TextButton
              onClick={() => {
                navigate("/services");
              }}
              text="Book a Consultation"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
