import React, { useEffect } from "react";
import TextButton from "../elements/TextButton";
import { useLocation, useNavigate } from "react-router-dom";

function Demo() {
  const location = useLocation();
  const navigate = useNavigate();
  const message = location.state?.message;

  useEffect(() => {
    if (!message) {
      navigate("/");
    }
    window.history.replaceState({}, document.title);
  }, [navigate, message]);

  const handleClick = () => {
    navigate("/");
  };

  return (
    <div className="mx-4 my-8">
      <div className="flex flex-col items-center gap-4 text-center">
        <h1 className="my-4 font-serif text-center sm:my-8 text-accent">
          DEMO MODE
        </h1>
        <div>{message}</div>
        <div>Thank you for browsing this trial website.</div>
        <div className="my-8">
          <TextButton text="Back to Home" onClick={handleClick} />
        </div>
      </div>
    </div>
  );
}

export default Demo;
