import React from "react";

interface TextButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  onClick?: () => void;
}

function TextButton(props: TextButtonProps) {
  props = { type: "button", ...props };

  return (
    <button
      type={props.type}
      className="p-4 rounded bg-main text-font-main"
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
}

export default TextButton;
