import React, { Fragment, useEffect, useRef, useState } from "react";
import { FormInfo, validateForm } from "../helpers/ValidateInput";
import TextButton from "../elements/TextButton";
import { useLocation, useNavigate } from "react-router-dom";
import { PlanInfo, ServiceInfo } from "./types";
import { motion } from "framer-motion";

type GroupType = "personal" | "message";

interface CheckoutFormInfo extends FormInfo {
  group: GroupType;
}

const initCheckoutFormInfos: Array<CheckoutFormInfo> = [
  {
    name: "firstName",
    label: "First Name",
    type: "text",
    group: "personal",
    value: "",
    errors: [],
    inputRules: [
      {
        name: "required",
        message: "Please fill in this field.",
      },
    ],
  },
  {
    name: "lastName",
    label: "Last Name",
    type: "text",
    group: "personal",
    value: "",
    errors: [],
    inputRules: [
      {
        name: "required",
        message: "Please fill in this field.",
      },
    ],
  },
  {
    name: "email",
    label: "Email",
    type: "email",
    group: "personal",
    value: "",
    errors: [],
    inputRules: [
      {
        name: "required",
        message: "Please fill in this field.",
      },
      {
        name: "email",
        message: "Please enter a valid email address.",
      },
    ],
  },
  {
    name: "mobile",
    label: "Mobile Number",
    type: "text",
    group: "personal",
    value: "",
    errors: [],
    inputRules: [
      {
        name: "mobile",
        message: "Please enter a a valid mobile number.",
      },
    ],
  },
  {
    name: "message",
    label: "Message",
    type: "field",
    group: "message",
    value: "",
    errors: [],
    inputRules: [],
  },
];

function Checkout() {
  const navigate = useNavigate();
  const location = useLocation();
  const productInfo = location.state?.product;
  const formRef = useRef<HTMLDivElement>(null);

  let [formInfos, setFormInfos] = useState<Array<CheckoutFormInfo>>(
    initCheckoutFormInfos
  );

  useEffect(() => {
    if (!productInfo) {
      navigate("/");
    }
  }, [navigate, productInfo]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    info: CheckoutFormInfo
  ) => {
    const updatedFormInfos = formInfos.map((formInfo) => {
      let updatedInfo = { ...formInfo };
      if (formInfo.name === info.name) {
        updatedInfo.value = event.target.value;
      }

      return updatedInfo;
    });

    setFormInfos(updatedFormInfos);
  };

  const handleInputBlur = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    info: CheckoutFormInfo
  ) => {
    const validatedInfos = validateForm(formInfos, ["required"]);
    setFormInfos(validatedInfos);
  };

  const handleFormSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();

    const validatedInfos = validateForm(formInfos);

    const isFormValid = validatedInfos.every(
      (info) => info.errors.length === 0
    );

    if (isFormValid) {
      navigate("/demo", {
        state: {
          message: "Product orders are not available in this mode.",
        },
      });
    } else {
      const top = formRef?.current?.offsetTop || 0;
      window.scrollTo({ top: top - 74, behavior: "smooth" });
      setFormInfos(validatedInfos);
    }
  };

  const renderInputElement = (info: CheckoutFormInfo) => {
    const inputClasses =
      info.errors.length > 0
        ? "flex-1 border-[1px] rounded-sm border-red-700 p-1"
        : "flex-1 border-[1px] rounded-sm border-slate-400 p-1";

    let jsxElement = (
      <input
        className={inputClasses}
        type={info.type}
        id={info.name}
        placeholder={info.label}
        onChange={(event) => {
          handleInputChange(event, info);
        }}
        onBlur={(event) => {
          handleInputBlur(event, info);
        }}
      ></input>
    );

    if (info.type === "field") {
      jsxElement = (
        <textarea
          className={inputClasses}
          id={info.name}
          placeholder={info.label}
          rows={6}
          onChange={(event) => {
            handleInputChange(event, info);
          }}
          onBlur={(event) => {
            handleInputBlur(event, info);
          }}
        ></textarea>
      );
    }

    return jsxElement;
  };

  const renderGroupInputElements = (group: GroupType) => {
    const infos = formInfos.filter((info) => info.group === group);

    return infos.map((info) => {
      return (
        <div key={info.name}>
          <div className="my-2 font-medium">
            <label
              className="font-medium"
              htmlFor={info.name}
            >{`${info.label} :`}</label>
          </div>
          <div className="flex">{renderInputElement(info)}</div>
          {info.errors.length > 0 && (
            <div className="text-red-700">{info.errors[0].message}</div>
          )}
        </div>
      );
    });
  };

  const renderPlanInfo = (info: PlanInfo) => {
    return (
      <div className="my-4">
        <div className="font-bold text-accent">{info.label.toUpperCase()}</div>
        <div className="my-4 text-main">{info.desc}</div>
        <div>{info.duration}</div>
      </div>
    );
  };

  const renderServiceInfo = (info: ServiceInfo) => {
    return (
      <div className="my-4">
        <div className="font-bold text-accent">{info.label.toUpperCase()}</div>
        <div>
          {info.date?.toLocaleString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}
        </div>
        <div>
          {info.date?.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        </div>
        <div>{info.location}</div>
        <div className="text-main">{info.consultant}</div>
        <div>1 hr</div>
      </div>
    );
  };

  const renderProductInfo = () => {
    switch (productInfo.type) {
      case "service": {
        return renderServiceInfo(productInfo.info);
      }
      case "plan": {
        return renderPlanInfo(productInfo.info);
      }
      default: {
      }
    }
  };

  return (
    <div className="my-8">
      {productInfo && (
        <Fragment>
          <h1 className="my-4 font-serif text-center sm:my-8 text-accent">
            CHECKOUT
          </h1>
          <motion.form
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            className="flex flex-col justify-center w-4/5 max-w-screen-md mx-auto sm:space-x-8 sm:flex-row lg:space-x-16"
            onSubmit={handleFormSubmit}
            noValidate={true}
          >
            <section className="sm:basis-6/12">
              <h2 className="py-4 border-b-2">Fill out your details</h2>
              <div className="my-4">Tell us about yourself</div>
              <div className="my-8" ref={formRef}>
                {renderGroupInputElements("personal")}
                {renderGroupInputElements("message")}
              </div>
            </section>
            <section className="sm:basis-4/12">
              <div className="w-4/5 max-w-sm mx-auto sm:w-full">
                <h2 className="py-4 border-b-2">Service Details</h2>
                <div className="my-4">{renderProductInfo()}</div>
                <hr />
                <div className="my-8">
                  <div>Payment Details</div>
                  <div className="flex items-center justify-between my-4">
                    <span>Total</span>
                    <span className="mx-4 text-lg font-bold text-slate-500">
                      {`₱${productInfo?.info?.price?.toLocaleString("En")}`}
                    </span>
                  </div>
                </div>
                <TextButton type="submit" text="Pay Now" />
              </div>
            </section>
          </motion.form>
        </Fragment>
      )}
    </div>
  );
}

export default Checkout;
