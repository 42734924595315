import React from "react";
import TextButton from "../elements/TextButton";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

type CoverlineInfo = {
  name: string;
  label: string;
  desc: string;
  page: string;
};

const coverlineInfos: Array<CoverlineInfo> = [
  {
    name: "services",
    label: "SERVICES",
    desc: "Ixius offers comprehensive consulting services tailored for large businesses. We are committed to providing exceptional insights with our professional analysts.",
    page: "/services",
  },
  {
    name: "projects",
    label: "PROJECTS",
    desc: "Discover a diverse range of financial consulting services offered by our firm through our projects. Trust us to help you achieve your financial goals.",
    page: "/projects",
  },
  {
    name: "planspricing",
    label: "PLANS & PRICING",
    desc: "Find the solution to business problems with our structured plans. Ixius provides a framework for businesses to grow and achieve efficient operations.",
    page: "/plans-and-pricing",
  },
];

function Coverline() {
  const navigate = useNavigate();

  const handleItemClick = (info: CoverlineInfo) => {
    navigate(info.page);
  };

  return (
    <motion.div
      initial={{ y: 100, opacity: 0 }}
      transition={{ ease: "easeOut", duration: 2 }}
      whileInView={{ y: 0, opacity: 1 }}
      viewport={{ once: true }}
    >
      <section className="w-4/5 max-w-screen-xl m-4 mx-auto text-center md:grid md:grid-cols-3 md:w-full md:justify-center">
        {coverlineInfos.map((info) => (
          <div key={info.name} className="my-10 md:p-4">
            <h2 className="my-4 font-serif text-accent">{info.label}</h2>
            <p className="flex items-center md:h-1/2">{info.desc}</p>
            <div className="my-4">
              <TextButton
                onClick={() => {
                  handleItemClick(info);
                }}
                text="More Info"
              />
            </div>
          </div>
        ))}
      </section>
    </motion.div>
  );
}

export default Coverline;
