import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { AnimatedPage } from "./pages";
import ResetScroll from "./helpers/ResetScroll";

function App() {
  ResetScroll();

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex-1 my-14">
        <AnimatedPage>
          <main>
            <Outlet />
          </main>
        </AnimatedPage>
      </div>
      <Footer />
    </div>
  );
}

export default App;
