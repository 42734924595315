import React, { useRef, useState } from "react";
import { FormInfo, validateForm } from "../helpers/ValidateInput";
import TextButton from "../elements/TextButton";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

type GroupType = "personal" | "message";

interface ContactFormInfo extends FormInfo {
  group: GroupType;
}

const initContactFormInfos: Array<ContactFormInfo> = [
  {
    name: "firstName",
    label: "First Name",
    type: "text",
    group: "personal",
    value: "",
    errors: [],
    inputRules: [
      {
        name: "required",
        message: "Please fill in this field.",
      },
    ],
  },
  {
    name: "lastName",
    label: "Last Name",
    type: "text",
    group: "personal",
    value: "",
    errors: [],
    inputRules: [
      {
        name: "required",
        message: "Please fill in this field.",
      },
    ],
  },
  {
    name: "email",
    label: "Email",
    type: "email",
    group: "personal",
    value: "",
    errors: [],
    inputRules: [
      {
        name: "required",
        message: "Please fill in this field.",
      },
      {
        name: "email",
        message: "Please enter a valid email address.",
      },
    ],
  },
  {
    name: "subject",
    label: "Subject",
    type: "text",
    group: "message",
    value: "",
    errors: [],
    inputRules: [
      {
        name: "required",
        message: "Please fill in this field.",
      },
    ],
  },
  {
    name: "message",
    label: "Message",
    type: "field",
    group: "message",
    value: "",
    errors: [],
    inputRules: [
      {
        name: "required",
        message: "Please fill in this field.",
      },
    ],
  },
];

function Contact() {
  const navigate = useNavigate();
  const [formInfos, setFormInfos] =
    useState<Array<ContactFormInfo>>(initContactFormInfos);
  const formRef = useRef<HTMLFormElement>(null);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    info: ContactFormInfo
  ) => {
    const updatedFormInfos = formInfos.map((formInfo) => {
      let updatedInfo = { ...formInfo };
      if (formInfo.name === info.name) {
        updatedInfo.value = event.target.value;
      }

      return updatedInfo;
    });

    setFormInfos(updatedFormInfos);
  };

  const handleInputBlur = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    info: ContactFormInfo
  ) => {
    const validatedInfos = validateForm(formInfos, ["required"]);
    setFormInfos(validatedInfos);
  };

  const handleFormSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();

    const validatedInfos = validateForm(formInfos);

    const isFormValid = validatedInfos.every(
      (info) => info.errors.length === 0
    );

    if (isFormValid) {
      navigate("/demo", {
        state: {
          message: "Submitting a message is not available in this mode.",
        },
      });
    } else {
      const top = formRef?.current?.offsetTop || 0;
      window.scrollTo({ top: top - 74, behavior: "smooth" });
      setFormInfos(validatedInfos);
    }
  };

  const renderInputElement = (info: ContactFormInfo) => {
    const inputClasses =
      info.errors.length > 0
        ? "flex-1 border-[1px] rounded-sm border-red-700 p-1"
        : "flex-1 border-[1px] rounded-sm border-slate-400 p-1";

    let jsxElement = (
      <input
        className={inputClasses}
        type={info.type}
        id={info.name}
        placeholder={info.label}
        onChange={(event) => {
          handleInputChange(event, info);
        }}
        onBlur={(event) => {
          handleInputBlur(event, info);
        }}
      ></input>
    );

    if (info.type === "field") {
      jsxElement = (
        <textarea
          className={inputClasses}
          id={info.name}
          placeholder={info.label}
          rows={6}
          onChange={(event) => {
            handleInputChange(event, info);
          }}
          onBlur={(event) => {
            handleInputBlur(event, info);
          }}
        ></textarea>
      );
    }

    return jsxElement;
  };

  const renderGroupInputElements = (group: GroupType) => {
    const infos = formInfos.filter((info) => info.group === group);

    return infos.map((info) => {
      return (
        <div key={info.name}>
          <div className="my-2 font-medium">
            <label
              className="font-medium"
              htmlFor={info.name}
            >{`${info.label} :`}</label>
          </div>
          <div className="flex">{renderInputElement(info)}</div>
          {info.errors.length > 0 && (
            <div className="text-red-700">{info.errors[0].message}</div>
          )}
        </div>
      );
    });
  };

  return (
    <div className="my-8">
      <div className="my-4 sm:w-4/5 sm:mx-auto">
        <h1 className="my-4 font-serif text-center text-accent">Contact</h1>
        <p className="w-4/5 mx-auto mb-8 text-center">
          Any inquiries or concerns? Send us a message with this form.
        </p>
      </div>
      <form
        className="w-4/5 max-w-screen-md mx-auto my-4"
        onSubmit={handleFormSubmit}
        noValidate={true}
        ref={formRef}
      >
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          className="flex flex-col justify-center sm:space-x-8 sm:flex-row"
        >
          <div className="flex-1">{renderGroupInputElements("personal")}</div>
          <div className="flex-1">
            {renderGroupInputElements("message")}
            <div className="my-4">
              <TextButton type="submit" text="Submit" />
            </div>
          </div>
        </motion.div>
      </form>
    </div>
  );
}

export default Contact;
