import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type IconButtonProps = {
  icon: IconProp;
  iconSize?: string;
  borderColor?: string;
  color?: string;
  onClick: () => void;
  active?: boolean;
  ariaLabel: string;
};

function IconButton(props: IconButtonProps) {
  props = {
    active: true,
    iconSize: "",
    borderColor: "border-transparent",
    color: "text-slate-600",
    ...props,
  };

  const opacity = props.active ? "opacity-100" : "opacity-50";
  const dynamicButtonClasses = [props.color, props.borderColor, opacity].join(
    " "
  );
  const dynamicIconClasses = [props.iconSize, props.color].join(" ");

  return (
    <button
      className={
        "w-16 h-16 rounded-full border-solid border-2 " + dynamicButtonClasses
      }
      onClick={props.onClick}
      disabled={!props.active}
      aria-label={props.ariaLabel}
    >
      <FontAwesomeIcon icon={props.icon} className={dynamicIconClasses} />
    </button>
  );
}

export default IconButton;
