import React from "react";
import logo1WebP from "../images/logo1-162w.webp";
import logo2WebP from "../images/logo2-162w.webp";
import logo3WebP from "../images/logo3-162w.webp";
import logo4WebP from "../images/logo4-162w.webp";
import logo1 from "../images/logo1-162w.png";
import logo2 from "../images/logo2-162w.png";
import logo3 from "../images/logo3-162w.png";
import logo4 from "../images/logo4-162w.png";
import { motion } from "framer-motion";

const clientsInfo = [
  {
    name: "divana",
    logo: logo1,
    logoWebP: logo1WebP,
    alt: "Divana",
  },
  {
    name: "nint",
    logo: logo2,
    logoWebP: logo2WebP,
    alt: "Nint",
  },
  {
    name: "bauch",
    logo: logo3,
    logoWebP: logo3WebP,
    alt: "Bauch",
  },
  {
    name: "eximity",
    logo: logo4,
    logoWebP: logo4WebP,
    alt: "Eximity",
  },
];

function Clients() {
  return (
    <section className="py-16 text-center">
      <h2 className="font-serif text-accent">OUR CLIENTS</h2>
      <div className="flex flex-wrap justify-center w-4/5 mx-auto my-8 md:w-full">
        {clientsInfo.map((info, index) => (
          <motion.div
            key={info.name}
            initial={{ y: 0, opacity: 0 }}
            transition={{ ease: "easeOut", duration: 2, delay: index * 0.2 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
          >
            <picture>
              <source srcSet={info.logoWebP} type="image/webp" />
              <source srcSet={info.logo} type="image/jpeg" />
              <img
                width={162}
                height={103}
                src={info.logo}
                alt={info.alt}
                className="w-fit"
                loading="lazy"
              />
            </picture>
          </motion.div>
        ))}
      </div>
    </section>
  );
}

export default Clients;
