import React from "react";
import Banner from "../components/Banner";
import Coverline from "../components/Coverline";
import Clients from "../components/Clients";
import Testimonials from "../components/Testimonials";

function Home() {
  return (
    <div>
      <Banner></Banner>
      <Coverline></Coverline>
      <Clients></Clients>
      <Testimonials></Testimonials>
    </div>
  );
}

export default Home;
