import React from "react";
import { motion } from "framer-motion";

type AnimatedProps = {
  className?: string;
  children?: React.ReactNode;
};

function AnimatedPage(props: AnimatedProps) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
      }}
      transition={{ ease: "easeOut", duration: 0.5 }}
      className={props.className}
    >
      {props.children}
    </motion.div>
  );
}

export default AnimatedPage;
