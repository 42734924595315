import React from "react";
import bannerWebp from "../images/about-banner-1024w.webp";
import profile1WebP from "../images/profile-1-375w.webp";
import profile2WebP from "../images/profile-2-375w.webp";
import profile3WebP from "../images/profile-3-375w.webp";
import profile4WebP from "../images/profile-4-375w.webp";
import banner from "../images/about-banner-1024w.jpg";
import profile1 from "../images/profile-1-375w.jpg";
import profile2 from "../images/profile-2-375w.jpg";
import profile3 from "../images/profile-3-375w.jpg";
import profile4 from "../images/profile-4-375w.jpg";
import { motion } from "framer-motion";

const profileInfos = [
  {
    name: "ChitoCanlas",
    label: "Chito Canlas",
    title: "Founder & CEO",
    desc: "Chito Canlas is a pioneer in the industry and joined the world of accounting at only 18 years of age. In 1981, he assumed leadership of Kita SeCO financials and renamed it to Ixius Consulting.\n\nAs founder and CEO, he leads and supports more than 151,000 talented people focused on delivering services to our clients in 81 countries. Chito ensures that everyone in Ixius is aligned to the values that made Ixius a trusted partner globally. ",
    image: profile1,
    imageWebP: profile1WebP,
  },
  {
    name: "KimVidal",
    label: "Kim Vidal",
    title: "Global Chairman",
    desc: "Kim Vidal was RKPN's head of financial services. She lead the firm's banking, insurance and asset management practice. Before that, she was a partner in Levaia Scauss Firm international office.\n\nShe has over three decades of experience supporting financial service companies with audit, transaction services and advisory projects.",
    image: profile2,
    imageWebP: profile2WebP,
  },
  {
    name: "JeffSagun",
    label: "Jeff Sagun",
    title: "Chief Digital Officer",
    desc: "Jeff Sagun has more than 20 years experience across the techonology sector. He has worked with clients at the board level to help drive change through enterprise-wide digital transformation, enabling organizations to adapt rapidly in the fast environment of software innovation.",
    image: profile3,
    imageWebP: profile3WebP,
  },
  {
    name: "LisaBasco",
    label: "Lisa Basco",
    title: "General Counsel",
    desc: "Lisa Basco is a practicing lawyer for over 30 years. She has broad experience across various arms of profession, including professional services firms in private practice, and understands the many challenging legal issues which arise for global organizations at both the local and international level.",
    image: profile4,
    imageWebP: profile4WebP,
  },
];

function About() {
  return (
    <div className="mx-4 my-8">
      <section>
        <h1 className="my-4 font-serif text-center sm:my-8 text-accent">
          ABOUT
        </h1>
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          className="block mx-auto sm:w-4/5 md:grid md:grid-cols-2 md:items-center 2xl:w-3/5"
        >
          <div className="my-8 sm:m-8">
            <p>
              Ixius helps other organizations grow and plan for the future with
              effective data-driven services. Our firms operate in 81 countries,
              serving the needs of businesses, governments, public-sector
              agencies and non-profits with our audit and assurance plans.
            </p>
            <br />
            <p>
              We are committed to enabling your organization to navigate
              industries safely with our risk-analysis measures and diverse
              strategies. We value the trust of our clients and we continue to
              provide excellent services to ensure sustainable financial growth.
            </p>
          </div>
          <div className="sm:h-full">
            <picture>
              <source srcSet={bannerWebp} type="image/webp" />
              <source srcSet={banner} type="image/jpeg" />
              <img
                width={1024}
                height={683}
                src={banner}
                alt="Our experts collaborating with a client in our central office"
                className="object-cover object-left w-full h-[50vh] sm:h-full bg-gray-100"
                loading="lazy"
              />
            </picture>
          </div>
        </motion.div>
      </section>
      <section>
        <div className="my-8 text-center">
          <h1 className="my-8 font-serif text-accent">OUR LEADERSHIP</h1>
          <p className="mb-8 sm:w-3/5 sm:mx-auto sm:mb-12">
            Our leaders are pioneers in their fields with decades of experience
            in the industry
          </p>
        </div>
        <div className="sm:grid sm:justify-center sm:grid-cols-2 sm:gap-10 sm:justify-items-center sm:mx-auto sm:w-4/5 2xl:w-3/5">
          {profileInfos.map((info, index) => (
            <motion.div
              initial={{ opacity: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="mx-auto mb-12 max-w-[375px]"
              key={info.name}
            >
              <picture>
                <source srcSet={info.imageWebP} type="image/webp" />
                <source srcSet={info.image} type="image/jpeg" />
                <img
                  width={375}
                  height={375}
                  src={info.image}
                  alt=""
                  className="object-cover w-full bg-gray-100"
                  loading="lazy"
                />
              </picture>
              <div className="my-4">
                <h2 className="font-bold text-main">
                  {info.label.toUpperCase()}
                </h2>
                <h3 className="font-bold text-slate-500">{info.title}</h3>
              </div>
              <p className="whitespace-pre-wrap ">{info.desc}</p>
            </motion.div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default About;
