import React, { Fragment, useEffect, useState } from "react";
import Calendar from "./../components/Calendar";
import TextButton from "../elements/TextButton";
import { ServiceInfo } from "./types";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

function Booking() {
  const todayDate = new Date();
  let navigate = useNavigate();
  const location = useLocation();
  const serviceInfo = location.state?.info;
  const [service, setService] = useState({ ...serviceInfo });

  useEffect(() => {
    if (!serviceInfo) {
      navigate("/");
    } else {
      const initSelectDate = new Date();
      initSelectDate.setDate(initSelectDate.getDate() + 1);
      initSelectDate.setHours(10);
      initSelectDate.setMinutes(0);
      setService({ ...serviceInfo, date: initSelectDate });
    }
  }, [navigate, serviceInfo]);

  const handlePayClick = (service: ServiceInfo) => {
    navigate("/checkout", {
      state: {
        product: {
          type: "service",
          info: service,
        },
      },
    });
  };

  const handleSelectDate = (date: Date) => {
    const newDate = new Date(date);

    newDate.setHours(service.date.getHours());
    newDate.setMinutes(service.date.getMinutes());

    setService({ ...service, date: newDate });
  };

  const handleSelectTime = (date: Date) => {
    const newDate = new Date(service.date);
    newDate.setHours(date.getHours());
    newDate.setMinutes(date.getMinutes());

    setService({ ...service, date: newDate });
  };

  const getTimeSlots = (date: Date) => {
    const startHour = 10;
    const endHour = 17;

    const timeSlots = [];
    for (var i = startHour; i <= endHour; i++) {
      let newDate1 = new Date(date);
      newDate1.setHours(i);
      newDate1.setMinutes(0);
      timeSlots.push(newDate1);

      if (i !== endHour) {
        let newDate2 = new Date(date);
        timeSlots.push(newDate2);
        newDate2.setHours(i);
        newDate2.setMinutes(30);
      }
    }

    return timeSlots;
  };

  const isSameTime = (dateA: Date, dateB: Date) => {
    const isSameTime =
      dateA.getHours() === dateB.getHours() &&
      dateA.getMinutes() === dateB.getMinutes();

    return isSameTime;
  };

  const renderTimeSlots = () => {
    const timeSlots = getTimeSlots(service.date);

    const defaultStyle = "px-4 py-2 m-2 text-center border-2";
    const selectedStyle = "px-4 py-2 m-2 text-center border-2 bg-blue-100";

    return (
      <div className="grid grid-cols-2">
        {timeSlots.map((slot, index) => {
          return (
            <button
              key={index}
              className={
                isSameTime(slot, service.date) ? selectedStyle : defaultStyle
              }
              onClick={() => {
                handleSelectTime(slot);
              }}
            >
              {slot.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
            </button>
          );
        })}
      </div>
    );
  };

  const renderServiceInfo = (service: ServiceInfo) => {
    const info = service;
    return (
      <div className="my-4">
        <div className="font-bold text-accent">{info.label.toUpperCase()}</div>
        <div>
          {service.date?.toLocaleString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}
        </div>
        <div>
          {service.date?.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        </div>
        <div>{service.location}</div>
        <div className="text-main">{service.consultant}</div>
        <div>1 hr</div>
      </div>
    );
  };

  return (
    <div className="mx-auto my-8">
      {serviceInfo && (
        <Fragment>
          <h1 className="my-4 font-serif text-center text-accent">BOOKING</h1>
          <div className="max-w-screen-xl m-auto w-fit md:w-full">
            <div className="w-4/5 m-auto sm:max-md:w-full sm:max-md:mx-0">
              <h2>{service.label.toUpperCase()}</h2>
              <div className="my-4">
                Check out our availability and book the date and time that works
                for you
              </div>
            </div>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              className="grid grid-cols-1 my-8 md:gap-8 md:w-4/5 lg:grid-cols-7 md:mx-auto"
            >
              <section className="lg:col-span-5">
                <h2 className="w-4/5 mx-auto border-b-2 sm:w-full sm:mx-0">
                  Select a Date and Time
                </h2>
                <div className="flex flex-col md:grid md:grid-cols-2 md:gap-8">
                  <div className="my-4 grow">
                    <Calendar
                      selectedDate={service.date}
                      handleSelectDate={handleSelectDate}
                      todayDate={todayDate}
                    />
                  </div>
                  <div className="text-center grow">
                    <div className="my-4">
                      {service.date.toLocaleDateString("en-US", {
                        weekday: "long",
                        month: "long",
                        day: "numeric",
                      })}
                    </div>
                    {renderTimeSlots()}
                  </div>
                </div>
              </section>
              <section className="my-8 lg:col-span-2 md:my-0">
                <div className="w-4/5 max-w-sm mx-auto lg:w-full">
                  <h2 className="border-b-2">Service Details</h2>
                  <div className="my-4">{renderServiceInfo(service)}</div>
                  <hr />
                  <div className="my-8">
                    <div>Payment Details</div>
                    <div className="flex items-center justify-between my-4 ">
                      <span>Total</span>
                      <span className="mx-4 text-lg font-bold text-slate-500">
                        {`₱${service.price.toLocaleString("En")}`}
                      </span>
                    </div>
                  </div>
                  <TextButton
                    type="submit"
                    text="Pay Now"
                    onClick={() => handlePayClick(service)}
                  />
                </div>
              </section>
            </motion.div>
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default Booking;
