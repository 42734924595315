import React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle as faCircleRegular } from "@fortawesome/free-regular-svg-icons";
import {
  faCircle as faCircleSolid,
  faCaretLeft,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../elements/IconButton";

const testimonialInfos = [
  {
    name: "testimonial1",
    text: "Ixius is a leading firm for global financial consulting. Partnering with them has enabled our organization to see significant improvements in our KPIs. Invaluable service and accessible pricing, even to small businesses.",
    author: {
      title: "Chief Operating Officer",
      name: "Mina Steppes",
    },
  },
  {
    name: "testimonial2",
    text: "Ixius's experts are among the best in the world. They have a host of brilliant minds, young and experienced alike. Most importantly, Ixius is a company that values data-driven analysis, providing grounded insights for sustainable results.",
    author: {
      title: "Financial Consultant",
      name: "River Jung Ji",
    },
  },
  {
    name: "testimonial3",
    text: "Ixius is a firm with a global reach and a wide network. Their market researchers are quick to adjust to current trends and they continue to provide us with fresh information regarding our organization's operations.",
    author: {
      title: "Head Market Analyst",
      name: "Luca Prins",
    },
  },
];

function Testimonials() {
  let [page, setPage] = useState(0);

  const handleArrowClick = (modifier: number) => {
    const maxPage = testimonialInfos.length - 1;
    let newPage = page + modifier;
    newPage = newPage > maxPage ? maxPage : newPage;
    newPage = newPage < 0 ? 0 : newPage;

    setPage(newPage);
  };

  let testimonialInfo = testimonialInfos[page];
  let pageActiveStates = testimonialInfos.map((info, index) => {
    return index === page;
  });

  return (
    <section className="p-8 text-center md:py-14 bg-neutral-200">
      <h2 className="font-serif text-accent">TESTIMONIALS</h2>
      <div className="flex flex-col justify-center h-96 sm:h-72">
        <p className="my-8 md:w-1/2 md:mx-auto">{testimonialInfo.text}</p>
        <div className="my-8">
          <div className="font-bold text-main">
            {testimonialInfo.author.title}
          </div>
          <div>{testimonialInfo.author.name}</div>
        </div>
      </div>

      <div className="flex items-center justify-center text-slate-700">
        <div className="mr-4 text-2xl">
          <IconButton
            ariaLabel="Go to previous testimonial"
            icon={faCaretLeft}
            iconSize="fa-2xl"
            onClick={() => {
              handleArrowClick(-1);
            }}
            active={!(page === 0)}
          />
        </div>
        {pageActiveStates.map((pageActive, index) => (
          <div key={index} className="mx-2">
            <FontAwesomeIcon
              icon={pageActive ? faCircleSolid : faCircleRegular}
            />
          </div>
        ))}
        <div className="ml-4 text-2xl">
          <IconButton
            ariaLabel="Go to next testimonial"
            icon={faCaretRight}
            iconSize="fa-2xl"
            onClick={() => {
              handleArrowClick(1);
            }}
            active={!(page === testimonialInfos.length - 1)}
          />
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
